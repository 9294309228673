﻿import * as $ from "jquery";
import "bootstrap/js/dist/collapse";
import tumGlobal from "../global.js";
import { ajaxSend } from "../global/fetch";
import searchHistory from "../searchHistory.js";
import { closeProgressbar, openProgressbar } from "../global/progressbar";
import { getPrecompiledTemplate, loadPrecompiledTemplates } from "../global/templates";
import { addEventDelegate } from "../global/dom";

import "../../css/global/searchMenu.css";
import { searchWidgets } from "../navbar/searchSelect.js";

var searchSuggestions = {
	options: {
		url: {
			getSearchSuggestions: "/search/getSearchSuggestions",
		},
		container: ".searchWidget .suggestions",
		searchField: ".searchWidget input"
	},
	delayMs: 500,
	delayTimeoutId: null,
	container: null,
	catalog: null,
	init: function(options){
		this.options = $.extend({}, this.options, options);
		this.initCallbacks();
	},
	initCallbacks: function(){
		var self = this;

		if (!self.options.searchField) return;

		this.container = $(this.options.container);

		addEventDelegate(document, 'input', self.options.searchField, element => {
			this._showSuggestions(element.value);
		});

		tumGlobal.onClickOutside(`${this.options.container}, ${this.options.searchField}`, () => this.close());

		addEventDelegate(document, 'click', '.searchSuggestionLink', element => {
			searchHistory.add($(element).attr('data-query'), $(element).attr('data-type'));
			openProgressbar()
			setTimeout(() => closeProgressbar(), 2000);
		});

		$(document).on('keydown', self.options.searchField, function(e){
			if (e.key == "ArrowDown" && $(self.options.container).length) {
				e.preventDefault();
				$(".searchSuggestionItem").first().find('a').first().trigger('focus');
			}
		});

		addEventDelegate(document, 'keydown', '.searchSuggestionItem', (element, e) => {
			let $items = $('.searchSuggestionItem', self.container);
			let itemIndex = $items.index($(element));

			if (e.key == 'ArrowDown') {
				e.preventDefault();
				if ($items.length == itemIndex+1) return;
				let item = $($items[itemIndex+1]);
				item.find('a').first().trigger('focus');
			}

			if (e.key == "ArrowUp") {
				e.preventDefault();

				if (itemIndex == 0){
					$(self.options.searchField).trigger('focus');
					return;
				}

				let item = $($items[itemIndex-1]);
				item.find('a').first().trigger('focus');
			}
		});

		$(document).on('show.bs.dropdown', () => self.close());

		this.container.on('click', '.catalog-showMore', function(){
			$('.moreCatalogItems', self.options.container).collapse('show');
			$(this).toggleClass('d-none d-flex');
		});

		this.container.on('click', '.catalog-expand', function(){
			let id = $(this).closest('.tu-catalog-items').attr('data-id');
			self.toggleCatalogChildren(id);
		});

		this.container.on('click', '.searchHistory-clear', function(){
			searchHistory.clear();
			$('.searchMenu-history', self.options.container).addClass('d-none');
		});

		this.container.on('click', '.searchButton', () => searchWidgets.main.sendQuery());

		this.container.on('click', '.searchHistory-item', function(){
			let query = $(this).attr('data-query');
			let type = $(this).attr('data-type');
			searchWidgets.main.search(query, type);
		});
	},
	_showSuggestions: function(query){
		if (this.delayTimeoutId) {
			clearTimeout(this.delayTimeoutId);
		}

		this.delayTimeoutId = setTimeout(() => {
			return loadPrecompiledTemplates(['search-dropdown', 'search-catalog-children'])
			.then(() => this.getViewModel(query))
			.catch(() => {})
			.then(data => this.show(data));
		}, this.delayMs);
	},
	getSearchSuggestions: function(query, type){
		var self = this;

		if (searchWidgets.main.getSearchType().code == "byFirmOffers") return;

		if (query.length < 2) {
			self.close();
			return Promise.resolve(null);
		}

		return new Promise((resolve, reject) => {
			ajaxSend({ url: self.options.url.getSearchSuggestions, data: { search: query } }).then(data => {
				if (data.result){
					data.query = query;
					data.searchType = type || searchWidgets.main.getSearchType().code;
					resolve(self.updateSearchSuggestionsModel(data));
				} else {
					reject('getSearchSuggestions error');
				}
			});
		})
	},
	updateSearchSuggestionsModel: function(data){
		if (!data) return null;
		let query = data.query.toLowerCase();
		let items = data.items;

		items.forEach(e => {
			e.htmlText = e.text.replace(query, `<b>${query}</b>`);
			e.tuCount = e.info?.count
		});

		let ctusItem = data.items.find(e => e.info && e.info.ctus && e.info.ctus.length);
		let ctus = [];
		if (ctusItem) {
			ctus = ctusItem.info.ctus;
			ctus.forEach(e => { 
				e.text = ctusItem.text; 
				e.htmlText = ctusItem.htmlText 
			});
		}
		
		let firms = data.items.find(e => e.info && e.info.firms && e.info.firms.length)?.info.firms || [];
		firms.forEach(e => {
			let regex = new RegExp(`(${query})`, 'gi');
			e.htmlName = e.name.replace(regex, '<b>$1</b>');
			e.htmlType = e.firmType?.replace(regex, '<b>$1</b>');
			e.contacts.sort((a, b) => Number(b.isMain) - Number(a.isMain));
			e.phone = e.contacts.find(e => e.code = "contact-phone");
			e.whatsapp = e.contacts.find(e => e.isWhatsapp);
		});

		let result = { 
			items: items, 
			ctus: ctus, 
			firms: firms, 
			query: query,
			showFirmsFirst: data.searchType != "byGoods",
			noResults: !items.length && !ctus.length && !firms.length
		};

		return result;
	},
	getViewModel: function(query){
		let result = {
			isMob: tumGlobal.isMob(),
			searchHistory: searchHistory.get(),
			searchQuery: query
		};

		result.hasSearchHistory = result.searchHistory.length;

		return this.getSearchSuggestions(query)
		.then(suggestions => {
			if (!suggestions) return Promise.reject('no search suggestions');

			result.searchSuggestions = suggestions;
			if (tumGlobal.isMob() && !this.catalog){
				return import("../catalogItems.js").then(({catItems}) => catItems.getCatalogItems());
			} else {
				return this.catalog;
			}
		})
		.then(catalog => {
			this.catalog = catalog;
			result.catalogItems = catalog;
		})
		.then(() => result);
	},
	show: function(data){
		if (!data) return;

		let template = getPrecompiledTemplate('search-dropdown');
		let $newHtml = $(template(data));

		this.container.html($newHtml);
		this.container.collapse('show');
	},
	close: function(){
		this.container.collapse('hide');
	},
	loadCatalogChildren: function(id){
		let $container = $(`.tu-catalog-items[data-id=${id}] .tum-catalog-children`, this.options.container);
		if ($container.hasClass('loaded')) return Promise.resolve();

		$container.addClass('loaded');

		return import("../searchMenu.js").then(({tum_searchMenu}) => {
			return import("../catalogItems.js")
			.then(({catItems}) => catItems.getCatalog(id))
			.then(items => { 
				$container.html(tum_searchMenu.renderCatalogChildren(items)) 
			});
		});
		
	},
	toggleCatalogChildren: function(id) {
		let $item = $(`.tu-catalog-items[data-id=${id}]`, this.options.container);
		let $children = $('.tum-catalog-children', $item);
		let $icon = $('.catalog-expand i', $item);

		this.loadCatalogChildren(id)
		.then(() => {
			$children.collapse('toggle');
			$icon.toggleClass('fa-chevron-down fa-chevron-up');
		});
	},
};

export default searchSuggestions;