import tumGlobal from "../global";
import { addEventDelegate } from "./dom";

/**
 * Регистрирует события, которые работают глобально, и нужный для них код загружается по требованию, а не сразу
 * Если импортируется модуль, в котором есть функция init(), ее нужно запускать в самом модуле
 * Импортируемый модуль должен отвечать за какой-то один функционал, не стоит здесь импортировать целый скрипт страницы фирмы или вроде того
 */
function initLazyModules() {
    addEventDelegate(document, 'click', '.toggleSearchMenu', () => {
        import("../searchMenu").then(({ tum_searchMenu }) => tum_searchMenu.toggleMenu());
    });

    addEventDelegate(document, 'click', '.home-catalog-link', (element, e) => {
        if (!tumGlobal.isMob()) return;
        e.preventDefault();
        import("../searchMenu").then(({ tum_searchMenu }) => {
            tum_searchMenu.toggleMenu().then(() => tum_searchMenu.scrollToCatalog());
        });
    });

    addEventDelegate(document, 'click', '.mod-ord-window', (element, event) => {
        event.preventDefault();
        var idFirm = element.getAttribute('data-firmid');
        var idProd = element.getAttribute('data-productid');
        var params = { idFirm: idFirm, idProd: idProd };

        import("../showCaseManagement").then(({ management }) => management.getModalData(params));
    });

    addEventDelegate(document, 'click', '.toggleMenu', () => {
        import("../MainMenu").then(({ tum_mainMenu }) => tum_mainMenu.toggleMenu());
    });

    addEventDelegate(document, 'click', '.editTopListDate', (element) => {
        import("../product/editTopListDate.js").then(({ editTopListDate }) => {
            let productId = element.getAttribute('data-itemid');
            let previewId = element.getAttribute('data-previewid');
            editTopListDate.editTopListDate(productId, previewId);
        });
    });

    addEventDelegate(document, 'click', '.tum-miniatureCartBtn', (element, event) => {
        event.preventDefault();
        import("../cart/cartWidget").then(({ cartWidget }) => cartWidget.showCartWidget(element));
    });

    addEventDelegate(document, 'click', ['.topbar-openCatalog', '.tu-showCatalog'], () => {
        import("../catalogItems").then(({ catItems }) => catItems.showCatalog());
    });

    addEventDelegate(document, 'click', '.firm-edit-item', (element, event) => {
        event.preventDefault();
        import("../firm/firmStatusEdit").then(({ firmStt }) => firmStt.editFirmStatus(event, element));
    });

    addEventDelegate(document, 'click', '.task-update', (element) => {
        import("../tasks.js").then(({ tu_tasks }) => tu_tasks.updateButtonHandler(element));
    });

    addEventDelegate(document, 'click', '[data-edit-preview-connections]', (element) => {
        let productId = element.getAttribute('data-productid');
        let firmId = element.getAttribute('data-firmid');
        let ctuId = element.getAttribute('data-ctuid');

        import("../previews/connection_editor").then(({previewConnectionEditor}) => {
            previewConnectionEditor.open({ productId, ctuId, firmId });
        });
    });

    refreshLazyModules();
}

function refreshLazyModules(){
    for (let crud of document.querySelectorAll('[data-crud]')) {
        import("../crud.js").then(({ initCrud }) => initCrud(crud));
    }

    if (document.querySelector('.tu-modal-form')) {
        import("../form/form.modals").then(({ initFormModals }) => initFormModals());
    }

    if (document.querySelector('[data-tu-select]')) {
        import("../global/tu_select").then(({ initSelects }) => initSelects());
    }

    for (let timer of document.querySelectorAll('[data-timer]')) {
        import("../global/timer").then(({ initTimer }) => initTimer(timer));
    }

    if (document.querySelector('[data-submit-form]')) {
        import("../global/form").then(({ initFormUtils }) => initFormUtils());
    }

    for (let item of document.querySelectorAll('[data-highlight-search]')) {
        import("../global/highlight_search").then(({ initHighlightSearch }) => {
            initHighlightSearch(item);
        });
    }
}

export { initLazyModules, refreshLazyModules };
