﻿/* global tumarket */

import * as $ from "jquery";
import "bootstrap/js/dist/tab";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/carousel";
import * as htmx from "htmx.org";

$.event.special.touchstart = {
	setup: function (_, ns, handle) {
		this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
	}
};
$.event.special.touchmove = {
	setup: function (_, ns, handle) {
		this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
	}
};
$.event.special.wheel = {
	setup: function (_, ns, handle) {
		this.addEventListener("wheel", handle, { passive: true });
	}
};
$.event.special.mousewheel = {
	setup: function (_, ns, handle) {
		this.addEventListener("mousewheel", handle, { passive: true });
	}
};

import notifications from "../notifications.js";
import tumPwa from "../pwa.js";
import hints from "../hints/hints.js";
import locationDetect from "../location.js";
import { tumStats } from "../statistics.js";
import cookNot from "../CookiesNotice.js";
import searchSuggestions from "../search/searchSuggestions.js";
import tumGlobal from "../global.js";
import tumCommon from "../common.js";
import contentMistakes from "../userContentMistakes/reportErrorWidget.js";
import { initSearchWidget } from "../navbar/searchSelect.js";
import { initCookies, setCookie } from "../global/cookie";
import { handleError, initErrorHandling } from "../global/debug";
import { initLazyModules, refreshLazyModules } from "../global/lazyHub";
import { backToTop } from "../backToTop.js";
import { editMode } from "../editmode.js";
import { initReadmore } from "../global/readmore";

//css
import "../../css/lib/bootstrap.scss";

import "@fontsource/open-sans/latin-400.css";
import "@fontsource/open-sans/latin-700.css";
import "@fontsource/open-sans/latin-400-italic.css";
import "@fontsource/open-sans/latin-700-italic.css";
import "@fontsource/open-sans/cyrillic-400.css";
import "@fontsource/open-sans/cyrillic-700.css";
import "@fontsource/open-sans/cyrillic-400-italic.css";
import "@fontsource/open-sans/cyrillic-700-italic.css";

import "../../css/modules/tu.m/base.css";
import "../../css/modules/tu.m/navbar/search.css";
import "../../css/modules/search/bottomActions.css";

import "../../css/new-catalog-template/css/style.css";
import "../../css/new-catalog-template/css/responsive.css";

import "../../css/global/global.css";
import "../../css/global/customCheckbox.css";
import "../../css/modules/banners/banners.css";

import { addEventDelegate } from "../global/dom";
import { initDialogs, initTuModal, showAlert, showDialog } from "../global/dialog";
import { openProgressbar } from "../global/progressbar";
import { initConsoleHandler } from "../browserConsole.js";
import { initBannerSliders, showRandomBannerAsPopup } from "../banners.js";
import { initTooltips, initTuPopover } from "../global/popover.js";
import { getSessionLength, initSessionTimer } from "../global/sessionTimer.js";

initConsoleHandler();
initSessionTimer();

function initHtmx() {
	htmx.onLoad(e => {
		initTuPopover(e);
		refreshLazyModules();
		document.body.dispatchEvent(new Event("tum.component.loaded"));

		let popover = e.closest('.popover');
		if (popover) {
			let popoverElement = $(popover).data("bs.popover").element;
			$(popoverElement).popover('update');
		}
	});

	//иногда почему-то сразу не отрабатывает htmx при загрузке страницы
	htmx.process(document.body);
}

window.addEventListener('load', () => {
	initErrorHandling();
	initCookies({ version: tumarket.cookieVersion, domain: tumarket.cookieDomain });
	initLazyModules();
	initDialogs();
	initTuModal();
	initReadmore();
	initTooltips();
	initSearchWidget();
	
	if (tumarket.isAuthenticated) {
		notifications.init();
	}

	tumCommon.init();

	tumPwa.init({
		isEmployee: tumarket.isEmployee
	});

	hints.init();

	backToTop.init();

	locationDetect.init({
		urlReferrer: tumarket.urlReferrer
	});

	cookNot.init();

	tumStats.init({
		urlReferrer: tumarket.urlReferrer
	});

	searchSuggestions.init();

	addEventDelegate(document, 'click', '.tum-shared', (element, e) => {
		e.preventDefault();
		if ('share' in navigator) {
			var data = {
				title: window.document.title,
				text: window.document.title,
				url: location.href
			};

			navigator.share(data).catch(() => {});
		}
		else {
			tumGlobal.copyToClipboard(location.href);
			showAlert('Ссылка скопирована в буфер обмена', { type: 'info' });
		}
	});

	addEventDelegate(document, 'click', '.resize-textarea-down', element => {
		var textarea = element.previousElementSibling.previousElementSibling;
		textarea.style.height = `${textarea.offsetHeight + 20}px`;
	});

	addEventDelegate(document, 'click', '.resize-textarea-up', element => {
		var textarea = element.previousElementSibling;
		var height = textarea.offsetHeight;
		if ((height - 20) > 50) {
			textarea.style.height = `${height - 20}px`;
		}
	});

	if (tumarket.searchWidget.searchSelectOption) {
		tumarket.searchWidget.searchSelectOptionsList.find(e => e.code == tumarket.searchWidget.searchSelectOption.code).selected = true;
	} else {
		tumarket.searchWidget.searchSelectOptionsList[0].selected = true;
	}

	contentMistakes.init();
	editMode.init();

	if (tumarket.isCourier && tumGlobal.isMob()) {
		import("../delivery/courierLocation.js").then(({ courierLocation }) => {
			courierLocation.init();
		});
	}

	initBannerSliders();

	let enablePopups = true;
	if (enablePopups) {
		let sessionLength = getSessionLength();
		if (sessionLength > 5) {
			showRandomBannerAsPopup();
		} else {
			setTimeout(() => showRandomBannerAsPopup(), 15000);
		}
	}

	if (tumarket.error) {
        showAlert(tumarket.error, { type: 'danger2' });
    }

	initHtmx();

	window.$ = $;
	window.setCookie = setCookie;
	window.showDialog = showDialog;
	window.showAlert = showAlert;
	window.handleError = handleError;
	window.addEvent = addEventDelegate;
	window.openProgressbar = openProgressbar;
});
