/* global tumarket */

import { closeDialog, showDialog } from "./global/dialog";
import { getStorageItem, setStorageItem } from "./global/storage";
import { getPrecompiledTemplate, loadPrecompiledTemplates } from "./global/templates";
import { isMob } from "./global/util";

function showWelcomeDialog(force, data){
    if (!data) data = {};

    data.city = tumarket.general.location.name;
    let interval =  null;

    let key = `disableBanner_${data.id}`;
    let bannerCloseInterval = tumarket.bannerCloseInterval * 1000 * 60 * 60;

    let disable = getStorageItem(key);

    if (!force && disable) return;

    return loadPrecompiledTemplates(['welcome-dialog']).then(() => {
        showDialog({
            content: getPrecompiledTemplate('welcome-dialog')(data),
            modal: 'welcome-dialog',
            pinToBottom: isMob(),
            onClosed: () => {
                setStorageItem(key, true, bannerCloseInterval);
                clearInterval(interval);
            },
            onShown: () => {
                let disableButton = document.querySelector('#welcome-dialog [data-welcome-disable]');
                disableButton?.addEventListener('click', () => {
                    setStorageItem(key, true, bannerCloseInterval);
                    closeDialog('#welcome-dialog');
                });

                let timerElement = document.querySelector('[data-welcome-timer]');
                let secondsLeft = 30;
                if (timerElement) {
                    timerElement.innerHTML = secondsLeft;
                    
                    interval = setInterval(() => {
                        secondsLeft--;
                        timerElement.innerHTML = secondsLeft;

                        if (!secondsLeft) {
                            clearInterval(interval);
                            closeDialog('#welcome-dialog');
                        }
                    }, 1000);
                }
            },
            showHeader: false,
            showFooter: false,
            size: 'sm',
            noBackdrop: true
        })
    });
}

export { showWelcomeDialog };