﻿/* global tumarket */

import { showDialog, closeDialog } from "./global/dialog";
import { getUrlParameter } from "./global/url";
import { getCookie, setCookie } from "./global/cookie";
import { addEventDelegate, appendHtml, toggleClass } from "./global/dom";
import { getSessionLength } from "./global/sessionTimer";

var tumPwa = {
	prompt: null,
	firstEvent: true,
	isPwa: false,
	options: {
		isEmployee: false
	},
	init: function (options) {
		Object.assign(tumPwa.options, options);

		if ("serviceWorker" in navigator) {
			if (navigator.serviceWorker.controller) {
				console.log("[PWA Builder] active service worker found, no need to register");
			} else {
				// Register the service worker
				navigator.serviceWorker
					.register("/pwabuilder-sw.js", {
						scope: "/"
					})
					.then(function (reg) {
						console.log("[PWA Builder] Service worker has been registered for scope: " + reg.scope);
					});
			}
		}

		if (window.matchMedia('(display-mode: standalone)').matches) {
			tumPwa.isPwa = true;
		}

		window.addEventListener('beforeinstallprompt', function (e) {
			e.preventDefault();
			tumPwa.prompt = e;

			if (!tumPwa.isPwa) {
				toggleClass(".tum-installPwa", "d-none", false);
				toggleClass(".tum-installPwaHowTo", "d-none", true);

				if (getUrlParameter("installpwa") == "" && window.screen.width < 993) {
					tumPwa.showInstallPwaModal();
					return;
				}

				if (
					getCookie("skipPwaInstall") != "1" 
					&& tumPwa.firstEvent 
					&& window.screen.width < 993
					&& getSessionLength()/60 > 3
				) {
					tumPwa.showInstallPwaModal();
				}
			}

			tumPwa.firstEvent = false;
		});

		addEventDelegate(document, 'click', ".tum-installPwa", () => {
			tumPwa.install();
		});

		addEventDelegate(document, 'click', ".tum-pwa-scrollDown", () => {
			setCookie("skipPwaInstall", "1", 30);
			closeDialog();

			window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
		});

		if (tumPwa.isPwa) {
			toggleClass(".tum-installPwaHowTo", "d-none", true);
			toggleClass(".tum-pwaOpenInBrowser", "d-none", false);
		}

		if ('getInstalledRelatedApps' in navigator) {
			navigator.getInstalledRelatedApps().then(function (e) {
				if (e.length) {
					toggleClass(".tum-installPwaHowTo", "d-none", true);

					if (getUrlParameter("installpwa") == "") {
						let isDev = location.href.indexOf("localhost") > -1 || location.href.indexOf("dev.") > -1;
						let title = '<span class="d-flex align-items-center" style="line-height:1.2em;"><img src="/Content/images/tu.m/logo/' + (isDev ? "dev" : "prod") + '/logo.png" alt="logo" class="d-inline mr-3">Приложение TU.Market</span>';
						let body = 'Приложение уже установлено на вашем устройстве.';
						showDialog({title: title, content: body, onShown: () => document.querySelector('#asModal .btn-primary').remove()});
					}
				}
			}).catch(error => {
				console.log(error);
			});
		}
	},
	install: function () {
		tumPwa.prompt.prompt();

		tumPwa.prompt.userChoice.then(function (result) {
			if (result.outcome === 'accepted') {
				toggleClass(".tum-installPwa", "d-none", true);
			}
		});
	},
	showInstallPwaModal: function () {
		if (tumarket.disablePopups) return;
		var isDev = location.href.indexOf("localhost") > -1 || location.href.indexOf("dev.") > -1;

		showDialog({
			title: '<span class="d-flex align-items-center" style="line-height:1.2em;"><img src="/Content/images/tu.m/logo/' 
				+ (isDev ? "dev" : "prod") + '/logo.png" alt="logo" class="d-inline mr-3">TU.Market</span>',
			content: "Установить приложение на устройство?",
			submitButtonText: "ДА",
			onSubmit: () => {
				closeDialog();
				tumPwa.install();

				import("./statistics").then(({ tumStats }) => {
					tumStats.logEvent({
						objectType: "pwa",
						eventType: "installPwa"
					});
				});
			}
		});
		
		let footer = document.querySelector("#asModal .modal-footer"),
			submitBtn = document.querySelector("#asModal .btn-primary"),
			cancelBtn = document.querySelector("#asModal .btn-default");

		appendHtml(footer, `
							<div>
								<div class="buttonsCont d-flex justify-content-end"></div>
								<div class="text-right mt-2 text-height-1-1">
								  <a href="javascript:void(0)" class="tum-grey tum-pwa-scrollDown font-italic">
									  ссылка для установки <br>
										доступна внизу сайта
									</a>
								</div>
							</div>`);

		let buttonsCont = document.querySelector("#asModal .buttonsCont");
		buttonsCont.append(submitBtn, cancelBtn);
		toggleClass(submitBtn, "pwa-agree", true);
		toggleClass(submitBtn, "btn-primary btn-light px-3 mr-2");

		cancelBtn.innerHTML = "Нет";
		cancelBtn.addEventListener("click", () => {
			setCookie("skipPwaInstall", "1", 30);
		});
	}
};

export default tumPwa;