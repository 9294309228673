let storageKey = 'sessionStart';

function initSessionTimer(){
    let currentTimer = localStorage.getItem(storageKey);
    if (currentTimer && Number(currentTimer)) {
        return;
    }

    localStorage.setItem(storageKey, Number(new Date()));
}

/**
 * 
 * @returns {number}
 */
function getSessionLength(){
    let sessionStartDate = null;
    
    let currentTimer = localStorage.getItem(storageKey);
    if (currentTimer && Number(currentTimer)) sessionStartDate = new Date(Number(currentTimer));
    else {
        return 0;
    }

    return ((new Date()) - sessionStartDate)/1000;
}

window.getSessionLength = getSessionLength;

export { initSessionTimer, getSessionLength };